var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "introduction-bg" }, [
        _c("div", { staticClass: "dialog-bg" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "bar" }),
            _vm._v(" "),
            _c("span", { staticClass: "en" }, [_vm._v("Privacy")])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Privacy Statement")]),
            _c("br"),
            _vm._v(
              "\n      Thank you for visiting the official website of Meta Stone Kitchen. We take your privacy seriously when you use our services. This Privacy Statement is intended to explain how we collect, use, and protect the personal information you provide to us.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Information Collection and Use")]),
            _c("br"),
            _vm._v(
              "\n      When you visit our website, we may collect your personal information, including but not limited to your name, email address, and contact details. We only collect necessary information to provide relevant services and do not share your personal information with any third parties.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Information Protection")]),
            _c("br"),
            _vm._v(
              "\n      We take security measures to protect the personal information you provide to us from unauthorized access, use, or disclosure. We are committed to making every reasonable effort to ensure the security of your personal information.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Cookie Usage")]),
            _c("br"),
            _vm._v(
              "\n      Our website may use Cookie technology to enhance user experience and provide you with more personalized services. You can choose to accept or decline cookies according to your needs.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Links to Other Sites")]),
            _c("br"),
            _vm._v(
              "\n      Our website may contain links to third-party websites, which may have their own privacy policies. Therefore, we recommend that you review their privacy policies before visiting these websites.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Changes to Privacy Statement")]),
            _c("br"),
            _vm._v(
              "\n      We reserve the right to update this Privacy Statement at any time. Any updates will be posted on this page. Therefore, we recommend that you regularly review this Privacy Statement to understand how we protect your personal information.\n      "
            ),
            _c("br"),
            _vm._v(
              "Thank you for trusting and choosing Meta Stone Kitchen. We are committed to providing you with the best service experience.\n      "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "placeholder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div>
    <mobag-swiper></mobag-swiper>
    <div class="mobag-search">
      <div style="max-width:1100px; width:100%; display:flex; align-items: center; justify-content:center;">
        <span class="title">{{ $t('searchByBike.title') }}</span>
        <search-by-bike></search-by-bike>
      </div>
    </div>
<!--    <brand-picture-map></brand-picture-map>-->

    <div class="mobag-hot" style="">
      <div class="hot">
        <img src="https://cdn.mobag.cn/a777c64d11674eef00b1b12a56348ddc" />
      </div>
      <b-container fluid>
        <b-row>
          <b-col v-for="product in productList" cols="6" sm="4" md="4" lg="3" :key="product.id">
            <product-item :product="product" :key="product.id" />
          </b-col>
        </b-row>
      </b-container>
      <div v-if="productList.length>0" class="mobag-more">
        <router-link :to="{name: 'productListByTag', params: {tagType: 0}}">More Products</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SearchByBike from './components/web/SearchByBike.vue'
import ProductHot from './components/web/ProductItem.vue'
import MobagSwiper from './components/web/Swiper.vue'
import BrandPictureMap from './components/web/BrandPictureMap.vue'


export default {
  data: function() {
    return {
      productList: [],
    };
  },
  components: {
    'search-by-bike': SearchByBike,
    'product-item': ProductHot,
    'mobag-swiper': MobagSwiper,
    'brand-picture-map': BrandPictureMap,
  },
  created: function() {
    window.document.title = 'Meta Stone';
    this.$api.get('/query_product_for_home')
      .then(data => {
        this.productList = data.data.productList;
      });
  }
}
</script>

<style scoped>
  .mobag-search{
    height:80px;
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    .title{flex:0 0 auto;
      margin-left:10px;
      font-size:2rem;
      font-weight:600;
      display: flex;
      margin-bottom:0
    }
  }
  .mobag-more {
    text-align: center;
    padding-bottom: 30px;
    a {
      padding: 20px;
      font-size: 1.6rem;
      color: black;
    }
  }
  .mobag-hot{
    max-width:1200px; margin:0 auto; padding-top:20px;
    .hot {
      text-align:center; padding-top:8px;
    }
    h2 {text-align:center; font-size:2.4rem}
    img {width:79px;}
    .row{margin: 20px 0;}
  }
  .col-sm-4, .col-md-4, .col-lg-3{padding:6px}
  @media screen and(max-width:575px) {
    .mobag-search{display: none;}
    .col-sm-4{padding:2px}
    .mobag-hot{
      .hot{padding-top:0}
      h2 {font-size:1.5rem}
      img {width:50px;}
    }
  }
</style>

<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">Privacy</span>
        </div>

        <p><b>Privacy Statement</b><br>
        Thank you for visiting the official website of Meta Stone Kitchen. We take your privacy seriously when you use our services. This Privacy Statement is intended to explain how we collect, use, and protect the personal information you provide to us.
        </p>
        <p><b>Information Collection and Use</b><br>
        When you visit our website, we may collect your personal information, including but not limited to your name, email address, and contact details. We only collect necessary information to provide relevant services and do not share your personal information with any third parties.
        </p>
        <p><b>Information Protection</b><br>
        We take security measures to protect the personal information you provide to us from unauthorized access, use, or disclosure. We are committed to making every reasonable effort to ensure the security of your personal information.
        </p>
        <p><b>Cookie Usage</b><br>
        Our website may use Cookie technology to enhance user experience and provide you with more personalized services. You can choose to accept or decline cookies according to your needs.
        </p>
        <p><b>Links to Other Sites</b><br>
        Our website may contain links to third-party websites, which may have their own privacy policies. Therefore, we recommend that you review their privacy policies before visiting these websites.
        </p>
        <p><b>Changes to Privacy Statement</b><br>
        We reserve the right to update this Privacy Statement at any time. Any updates will be posted on this page. Therefore, we recommend that you regularly review this Privacy Statement to understand how we protect your personal information.
        <br>Thank you for trusting and choosing Meta Stone Kitchen. We are committed to providing you with the best service experience.
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = 'Meta Stone - Privacy';
  }
}
</script>

<style scoped>
  .title {
    left: -155px;
  }
  .placeholder {height:52%;}
  @media (max-width:900px) {
    .introduction-bg {
    .title {
      left:0;
      top: -100px;
    }
  }
  }
</style>

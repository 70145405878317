<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div class="banner">
    <swiper :options="swiperOption" ref="bannerSwiper" style="position:relative" v-if="banners.length">
      <!-- slides -->
<!--      <swiper-slide v-for="(banner,index) in banners" :key="index"><img :src="banner.url" @click="toDetail(banner)"></swiper-slide>-->
      <swiper-slide v-for="(banner,index) in banners" :key="index"><img :src="banner.url"></swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination">
      </div>
      <div class="swiper-button" slot="button-prev">
        <a class="arr-container swiper-button-prev" style="left:5%">
          <v-icon name="chevron-left" scale="1.1" color="#CED4DA" class="arr"/>

        </a>
        <a class="arr-container swiper-button-next" style="right:5%">
          <v-icon name="chevron-right" scale="1.1" color="#CED4DA" class="arr"/>
        </a>
      </div>
    </swiper>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {
        arrMaskWidth: '10%',
        productList: [],
        swiperOption: {
          // minHeight:400,
          autoHeight: true,
          // slidesPerView: 'auto',
          centeredSlides: true,
          spaceBetween: 0,
          loop: true,
          // loopedSlides: 3,
          autoplay: {
            delay: 3000,
          },
          observer: true,
          observeParents: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        banners: [
          // {
          //   url: 'https://cdn.mobag.cn/pic1.png',
          //   path: '/home/product/detail/722809'//详情页
          // },
          // {
          //   url: 'https://cdn.mobag.cn/pic2.png',
          //   path: '/home/product/list/tag/0?tags=11,12&brands=3,4'//配件0，装备4
          // },
          // {
          //   url: 'https://cdn.mobag.cn/pic3.png',
          //   path: '/home/product/list/search?keyword=排气&tags=10&brands=2'//关键词搜索
          // },
          // {
          //   url: 'https://cdn.mobag.cn/pic4.png',
          //   path: '/home/product/list/bike?motoModelId=50&year=2023'//车型搜索
          // }
        ]
      };
    },
    created() {
      this.$api.get('/query_banners?type=2')
        .then(res => {
          this.banners = res.data
        });
    },
    mounted() {
      const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          that.arrMaskWidth = (document.body.clientWidth - 1000) / 2
        })()
      }
    },
    methods: {
      toDetail(item) {
        if(item.path) {
          const route = this.$router.resolve({
            path: item.path
          })
          window.open(route.href,"_blank")
        }
      }
    },
  }
</script>

<style scoped>
  .banner{ margin: 0 auto;}
  .banner img{ width:100%; height: auto; display: block;}
  .arr {position:absolute; top:24px; left:18px;}
  .arr-container { position: absolute; top:42%; z-index:2; display: block; background-image:none; background-color:rgba(0,0,0,0.6); width:50px; height:70px;}
  .arr-container{ font-size:2rem; color: white; background-image:none;}
  .arr-container:hover { color:#ec2f00}
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {left:20px;}
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {right:20px;}
  @media screen and (max-width: 1000px) {
    .swiper-button{ display: none}
  }
  @media screen and (max-width: 565px) {
    .banner{ margin-top:80px;}
  }
</style>
<style>
  .swiper-pagination-bullet{
    width:80px; height:4px; border:0; border-radius:0;
    background-color: rgba(255,255,255,0.6);
  }
  .swiper-pagination-bullet-active{
    background-color: #ec2f00;
  }
  .swiper-wrapper {
    height: fit-content;
  }
</style>

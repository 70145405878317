function numberToPrice(number) {
  return `¥${(parseFloat(number) / 100).toFixed(2)}`;
}

function getPageTotalByTotalCount(totalCount, pageSize) {
  let pageTotal = totalCount / pageSize;
  if (totalCount % pageSize) {
    pageTotal = Math.ceil(pageTotal);
  }
  return pageTotal;
}

function findItemIndexFromArray(array, item) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (array[i] === item) {
      return i;
    }
  }
  return false;
}

function isItemInArray(array, item) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (array[i] === item) {
      return true;
    }
  }
  return false;
}

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

function operaUrlTo400(url) {
  if (!url) {
    return url;
  }
  const checkUrl = url.search('imageView');
  if (checkUrl !== -1) {
    const base = url.split('/w/')[0];
    return `${base}/w/400/h/400`;
  }
  return `${url}?imageView2/2/w/400/h/400`;
}

function operaUrlTo100(url) {
  if (!url) {
    return url;
  }
  const checkUrl = url.search('imageView');
  if (checkUrl !== -1) {
    const base = url.split('/w/')[0];
    return `${base}/w/100/h/100`;
  }
  return `${url}?imageView2/2/w/100/h/100`;
}

function scrollTo(element, to, duration) {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 10;

  setTimeout(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
}

function isScrollToBottom() {
  return document.scrollingElement.scrollHeight
    - document.scrollingElement.scrollTop === document.scrollingElement.clientHeight;
}

function isScrollToTop() {
  return document.scrollingElement.scrollTop === 0;
}

function getRandomSelQrCode() {
  let qrImageURLs = ["https://cdn.mobag.cn/c6cdd3b0bc45fcbda2d063db75aa652d",
        "https://cdn.mobag.cn/8ccf8f87780f9091f76d867c0f315d11",
        "https://cdn.mobag.cn/xiaopeng_qrcode.jpeg"]
  var randomIndex = Math.floor(Math.random() * qrImageURLs.length);
  return qrImageURLs[randomIndex]
}

export {
  numberToPrice,
  getPageTotalByTotalCount,
  findItemIndexFromArray,
  isItemInArray,
  formatDate,
  operaUrlTo100,
  operaUrlTo400,
  scrollTo,
  isScrollToBottom,
  isScrollToTop,
  getRandomSelQrCode
};

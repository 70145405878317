<template>
  <product-list :props="props" />
</template>

<script>
import ProductList from './ProductList.vue'
export default {
  data: function() {
    return {
      props: {
        productUrl: '',
        tagType: null,
        searchInfo: null,
      }
    }
  },
  components: {
    'product-list': ProductList,
  },
  computed: {
    tags() {
      return this.$route.query.tags || ''
    },
    brands() {
      return this.$route.query.brands || ''
    },
    bikeBrandId() {
      return this.$route.query.bikeBrandId
    },
    bikeSeriesId() {
      return this.$route.query.bikeSeriesId
    },
    motoModelId() {
      return this.$route.query.motoModelId
    },
    year() {
      return this.$route.query.year 
    }
  },
  created: function() {
    this.updateProps(this.bikeBrandId, this.bikeSeriesId, this.motoModelId, this.year)
  },
  beforeRouteUpdate: function(to, from, next) {
    this.updateProps(to.query.bikeBrandId || '',to.query.bikeSeriesId || '',to.query.motoModelId || '', to.query.year || 0, () => {
      next();
    })
  },
  
  methods: {
    updateProps(bikeBrandId='', bikeSeriesId='', motoModelId='', year=0, callback) {
      this.$api.get(`/get_moto_model_by_id?id=${motoModelId}`)
      .then(data => {
        let searchInfo = `${data.data.motoBrandName} > ${data.data.motoSeriesName} > ${data.data.name}`
        if(year>0){
          // searchInfo = searchInfo + ` > ${year}年款`
          searchInfo = searchInfo + ''
        }
        this.props = {
          productUrl: `/search_product_by_bike?moto_model_id=${motoModelId}&year=${year}`,
          tagUrl: `/query_tag_list_by_bike?moto_model_id=${motoModelId}&year=${year}`,
          brandUrl: `/query_product_brand_by_bike?moto_model_id=${motoModelId}&year=${year}`,
          tagType: 0,
          searchInfo: searchInfo,
          tags: this.tags,
          brands: this.brands,
          currentBikeSelection: [ bikeBrandId, bikeSeriesId, motoModelId ]
        };
        // console.log('111:' + JSON.stringify(this.props))
        callback && callback()
      });
    }
  }
}
</script>

<style scoped>
</style>

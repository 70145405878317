var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "mobag-header" }, [
    _c("div", { staticClass: "dealer-login", staticStyle: { height: "5px" } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mobag-header-container" },
      [
        _c(
          "div",
          {
            staticClass: "mobag-menu-icon",
            on: {
              click: function($event) {
                return _vm.tangleMenuModal()
              }
            }
          },
          [_c("v-icon", { attrs: { name: "bars" } })],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("nav", { staticClass: "mobag-nav" }, [
          _c(
            "a",
            {
              staticClass: "nav_a",
              attrs: { href: "#" },
              on: {
                click: function(e) {
                  _vm.reloadPage("/home", e)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("header.homepage")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav_a",
              attrs: { href: "#" },
              on: {
                click: function(e) {
                  _vm.reloadPage("/home/product/list/tag/0", e)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("header.parts")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav_a",
              attrs: { href: "#" },
              on: {
                click: function(e) {
                  _vm.reloadPage("/home/product/list/tag/4", e)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("header.allGears")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://dealer.nymetastone.com/"
              }
            },
            [_vm._v(_vm._s(_vm.$t("header.dealerLogin")))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobag-search" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchKeyword,
                expression: "searchKeyword"
              }
            ],
            attrs: {
              type: "text",
              name: "search_input",
              placeholder: _vm.$t("header.searchPlaceHolder")
            },
            domProps: { value: _vm.searchKeyword },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.search()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchKeyword = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.search()
                }
              }
            },
            [
              _c("v-icon", {
                staticClass: "icon-search",
                attrs: { name: "search" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mobag-search-mobile" }, [
          _c(
            "a",
            {
              staticClass: "mobag-search-mobile",
              on: {
                click: function($event) {
                  return _vm.tangleSearchModal()
                }
              }
            },
            [
              _c("v-icon", {
                staticClass: "icon-search",
                attrs: { name: "search" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("mobile-search", {
          ref: "mobileSearch",
          on: {
            modalSearch: _vm.getSearchParam,
            tangle: function($event) {
              return _vm.tangleSearchModal()
            }
          }
        }),
        _vm._v(" "),
        _c("mobile-menu", {
          ref: "mobileMenu",
          on: {
            tangle: function($event) {
              return _vm.tangleMenuModal()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mobag-logo" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("img", {
          attrs: { src: "https://cdn.mobag.cn/metastonelogoOnly600.png" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mobag-swiper"),
      _vm._v(" "),
      _c("div", { staticClass: "mobag-search" }, [
        _c(
          "div",
          {
            staticStyle: {
              "max-width": "1100px",
              width: "100%",
              display: "flex",
              "align-items": "center",
              "justify-content": "center"
            }
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("searchByBike.title")))
            ]),
            _vm._v(" "),
            _c("search-by-bike")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mobag-hot" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                _vm._l(_vm.productList, function(product) {
                  return _c(
                    "b-col",
                    {
                      key: product.id,
                      attrs: { cols: "6", sm: "4", md: "4", lg: "3" }
                    },
                    [
                      _c("product-item", {
                        key: product.id,
                        attrs: { product: product }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.productList.length > 0
            ? _c(
                "div",
                { staticClass: "mobag-more" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "productListByTag", params: { tagType: 0 } }
                      }
                    },
                    [_vm._v("More Products")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hot" }, [
      _c("img", {
        attrs: { src: "https://cdn.mobag.cn/a777c64d11674eef00b1b12a56348ddc" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="searchContainer" id="wechatSearchByBike">

    <hr data-content="Search By Product Name" class="hr-text">

    <input class="wechatSearchInput" type="text" v-model="searchKeyword" @keydown.enter="directSearch()" placeholder="Search By Product Name" />
    <btn-orange :click="directSearch"
                text="Search"
                class="search"/>

<!--    <hr style="margin: 80px 0 30px 0;" data-content="按车型搜索" class="hr-text">-->

<!--    <div class="search-row">-->
<!--        <b-dropdown :text="selectedBrandName || '品牌'" class="dp-item">-->
<!--          <b-dropdown-item @click="saveSelection('brand', brand.id, brand.name)"-->
<!--                           v-for="brand in showBrandList"-->
<!--                           :value="brand.id"-->
<!--                           :key="brand.id"-->
<!--          >{{brand.name}}</b-dropdown-item>-->
<!--        </b-dropdown>-->
<!--        <v-icon name="angle-down" class="icon" scale="1.4" />-->
<!--      </div>-->
<!--    <div class="search-row">-->
<!--      <b-dropdown :text="selectedSeriesName || '车系'" class="dp-item" >-->
<!--        <b-dropdown-item @click="saveSelection('series', series.id, series.name)"-->
<!--                         v-for="series in showSeriesList"-->
<!--                         :value="series.id"-->
<!--                         :key="series.id"-->
<!--        >{{series.name}}</b-dropdown-item>-->
<!--      </b-dropdown>-->
<!--      <v-icon name="angle-down" class="icon" scale="1.4" />-->
<!--    </div>-->

<!--    <div class="search-row">-->
<!--      <b-dropdown :text="selectedModelName || '车型'" class="dp-item" >-->
<!--        <b-dropdown-item @click="saveSelection('model', model.id, model.name)"-->
<!--                         v-for="model in showModelList"-->
<!--                         :value="model.id"-->
<!--                         :key="model.id"-->
<!--        >{{model.name}}</b-dropdown-item>-->
<!--      </b-dropdown>-->
<!--      <v-icon name="angle-down" class="icon" scale="1.4" />-->
<!--    </div>-->

<!--    <div class="search-row">-->
<!--      <b-dropdown :text="selectedYear || '年份'" class="dp-item" >-->
<!--        <b-dropdown-item @click="saveSelection('year', null, year.toString())"-->
<!--                         v-for="year in showYearList"-->
<!--                         :value="year"-->
<!--                         :key="year"-->
<!--        >{{year}}</b-dropdown-item>-->
<!--      </b-dropdown>-->
<!--      <v-icon name="angle-down" class="icon" scale="1.4" />-->
<!--    </div>-->
<!--    <btn-orange :click="search"-->
<!--                text="搜索"-->
<!--                class="search"-->
<!--    />-->

  </div>
</template>

<script>
  import ButtonOrange from "../components/mobile/ButtonOrange.vue";

  export default {
    components: {
      'btn-orange':ButtonOrange,
    },
    props: {
      searchCallBack: {
        type: Function,
        default: ()=>{}
      },
      linkCallBack: {
        type: Function,
        default: function(){
          this.$router.push({
            name:'SearchByNameForWechat'
          });
        }
      }
    },
    data: function() {
      return {
        motoModelList: [],
        showBrandList: [],
        showSeriesList: [],
        showModelList: [],
        showYearList: [],
        selectedBrandId: null,
        selectedBrandName: null,
        selectedSeriesId: null,
        selectedSeriesName: null,
        selectedModelId: null,
        selectedModelName: null,
        selectedYear: null,
        searchKeyword: '',
        brands: ''
      }
    },
    created: function() {
      for (let i = (new Date()).getFullYear() + 1; i > 2010; i--) {
        this.showYearList.push(i);
      }
      this.$api.get('/query_moto_model_list')
        .then(data => {
          this.motoModelList = data.data.motoModelList;
          for (let motoModel of this.motoModelList) {
            if (this.showBrandList.findIndex(x => { return x.id == motoModel.motoBrandId }) == -1)
              this.showBrandList.push({
                id: motoModel.motoBrandId,
                name: motoModel.motoBrandName,
              });
          }
        });
    },
    watch: {
      selectedBrandId: function(newSelectedBrandId, oldSelectedBrandId) {
        this.showSeriesList = [];
        this.showModelList = [];
        this.selectedModelId = null;
        this.selectedSeriesId = null;
        this.selectedModelName = null;
        this.selectedSeriesName = null;
        for (let motoModel of this.motoModelList.filter(x => { return x.motoBrandId == newSelectedBrandId })) {
          if (this.showSeriesList.findIndex(x => { return x.id == motoModel.motoSeriesId }) == -1)
            this.showSeriesList.push({
              id: motoModel.motoSeriesId,
              name: motoModel.motoSeriesName,
            });
        }
      },
      selectedSeriesId: function(newSelectedSeriesId, oldSelectedSeriesId) {
        this.showModelList = [];
        this.selectedModelName = null;
        this.selectedModelId = null;
        for (let motoModel of this.motoModelList.filter(x => { return x.motoSeriesId == newSelectedSeriesId })) {
          this.showModelList.push({
            id: motoModel.id,
            name: motoModel.name,
          });
        }
      },
    },
    methods: {
      saveSelection: function(type, id, name){
        switch (type) {
          case 'brand':
            this.selectedBrandId = id;
            this.selectedBrandName = name;
            break;
          case 'series':
            this.selectedSeriesId = id;
            this.selectedSeriesName = name;
            break;
          case 'model':
            this.selectedModelId = id;
            this.selectedModelName = name;
            break;
          case 'year':
            this.selectedYear = name;
        }
      },
      search: function() {
        if (this.selectedBrandId == null) {
          this.$modalHelper.alert('请选择车辆品牌进行搜索');
          return;
        }
        if (this.selectedSeriesId == null) {
          this.$modalHelper.alert('请选择车系进行搜索');
          return;
        }
        if (this.selectedModelId == null) {
          this.$modalHelper.alert('请选择车型进行搜索');
          return;
        }
        if (this.selectedYear == null) {
          this.$modalHelper.alert('请选择年份进行搜索');
          return;
        }
        this.$router.push({
          name: 'productListByBike',
          query: {
            motoModelId: this.selectedModelId,
            year: this.selectedYear,
          },
        });
        this.searchCallBack();
      },
      directSearch() {
        if (this.searchKeyword.length == 0)
          return;
        this.$router.push({
          name: 'productListBySearch',
          query: { keyword: this.searchKeyword, brands: this.brands },
        });
        this.searchCallBack();
      },
      linkToSearchByName: function() {
        this.linkCallBack();
      }
    },
  }
</script>

<style scoped>
  label, select {font-size:1.4rem; line-height:2.8rem;}
  .searchContainer {
    margin: 0 auto;
    padding: 24px 10px 10px;
    color:#333
  }
  .search-row {width:100%; margin-bottom:12px; position:relative}
  .dp-item {
    border: solid 1px #d5d5d5;
    height:40px;
    width:100%;
    background-color:white;
    font-size:1.2rem;
  }
  .icon {
    position: absolute;
    right:10px;
    top:12px;
    font-size:2rem;
    color:#ec2f00;
    &:hover {color: #ec2f00;
       visibility: visible;
     }
  }
  .search{
    width:100%;
    height:40px;
    font-size:1.4rem;
    background-color:#ed4218;
    &:active, &:focus, &:hover {font-size:1.4rem;}
  }
  .link {
    text-align: center;
    font-size:1.2rem;
    display:block;
    color: #333;
    margin-top:24px;
  }
  .wechatSearchInput {
    height: 40px;
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    margin: 12px 0;
    font-size:1.2rem;
    padding:10px;
    width:100%;
  }
  hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #999;
}

  hr.hr-text::before {
      content: attr(data-content);
      display: inline-block;
      background: #fff;
      font-weight: bold;
      font-size: 1.5rem;
      color: #999;
      border-radius: 30rem;
      padding: 0.2rem 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
  .search:hover, .search:visited {color: #ccc}
  .link:active, .link:visited, .link:hover {color: #999}
</style>
<style>
  #wechatSearchByBike .dp-item > .dropdown-toggle::after { border:0 }
  #wechatSearchByBike .dp-item button {text-align:left; font-size:1.2rem; margin-right:20px; overflow:hidden; text-overflow:ellipsis;}
  #wechatSearchByBike a.dropdown-item, button.dropdown-item {font-size:1.2rem}
  #wechatSearchByBike .dropdown-menu.show {width:100%}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "introduction-bg" }, [
        _c("div", { staticClass: "dialog-bg" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "bar" }),
            _vm._v(" "),
            _c("span", { staticClass: "en" }, [_vm._v("Q & A")])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Frequently Asked Questions (FAQ)")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Welcome to the Meta Stone Kitchen FAQ page. Here are some commonly asked questions along with their answers.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("What services do you offer?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Meta Stone Kitchen offers custom kitchen cabinet and countertop design, fabrication, and installation services. We are committed to providing high-quality, personalized kitchen products to our customers.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("What is the quality of your products?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        We promise to use high-quality materials and exquisite craftsmanship to ensure that each customer receives the best value and durability.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("How can I contact your customer service team?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        You can reach out to our customer service team via phone or email. Please call our service hotline during business hours or feel free to email us at any time. We will respond to your inquiries as soon as possible.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("Do you provide free quotes?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Yes, we offer free on-site surveys and quoting services. Please contact our customer service team to schedule an appointment.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("What areas do you serve?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Our services cover Long Island, New York, and the surrounding areas. If you are unsure whether you are within our service area, please contact us for more information.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v("How do I schedule installation services?")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(
              "\n        Once you confirm your order and select the products, our team will work with you to arrange the most suitable installation time. We ensure efficient and professional installation services in the shortest possible time.\n      "
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        If you have any other questions or need further information, please feel free to contact our customer service team.\n      "
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "placeholder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">About Us</span>
        </div>
        <p>Welcome to Meta Stone Kitchen!</p>
        <p><b>Company Overview:<br></b>Meta Stone Kitchen is a kitchen cabinet and countertop design, fabrication, and installation company located in Farmingdale, Long Island, New York. We take pride in providing high-quality, cost-effective custom kitchen products.</p>
        <p><b>Mission and Values:<br></b>Our mission is to provide customers with the best value kitchen solutions, ensuring 100% customer satisfaction. Crafting Excellence, Delivering Value - Your Kitchen, Our Passion.</p>
        <p><b>Our Team:<br></b>The team at Meta Stone Kitchen consists of passionate, experienced professionals dedicated to meeting customer needs. Our designers, craftsmen, and installation team are committed to turning your dream kitchen into a reality.</p>
        <p><b>Our Commitment:<br></b>At Meta Stone Kitchen, our commitment is to consistently exceed customer expectations. We achieve this by listening to your needs, offering personalized designs, using high-quality materials, and delivering exquisite craftsmanship to create your ideal kitchen.</p>
        <p><b>Corporate Social Responsibility:<br></b>We are dedicated to addressing social and environmental issues, actively participating in community activities and environmental initiatives, and striving to create a more sustainable future.
        <br>Thank you for visiting the official website of Meta Stone Kitchen. Let us work together to create your dream kitchen!</p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = 'Meta Stone - About Us';
  }
}
</script>
<style scoped>
  .placeholder {height:45%;}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchContainer", attrs: { id: "wechatSearchByBike" } },
    [
      _c("hr", {
        staticClass: "hr-text",
        attrs: { "data-content": "Search By Product Name" }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchKeyword,
            expression: "searchKeyword"
          }
        ],
        staticClass: "wechatSearchInput",
        attrs: { type: "text", placeholder: "Search By Product Name" },
        domProps: { value: _vm.searchKeyword },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.directSearch()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchKeyword = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("btn-orange", {
        staticClass: "search",
        attrs: { click: _vm.directSearch, text: "Search" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <b-container fluid style="max-width:1200px; padding:10px 0; margin:10px auto">
      <b-row class="flex row-container">

        <!-- web filter -->
        <div class='filter' id="webFilter">
          <div class="cat-container" style="margin-top:-20px">
            <h2></h2>
            <b-form-checkbox class="card-text" style="font-size: 14px;margin-left: 8px;font-weight: bolder;"
              id="inventoryCheckbox"
              v-model="inventoryChecked"
              name="inventoryCheckbox"
              @input="changeInventoryCheckbox">Inventory</b-form-checkbox>
          </div>
          <div class="cat-container" style="margin-top:-20px">
            <h2></h2>
            <webFilter
              :list="tagList"
              :collaped=0
              @onSelectChange="onTagSelectChange"
              :selected="selectedTagIdList"
            />
          </div>
          <!--div class="cat-container">
            <h2>品牌筛选</h2>
            <webFilter
              :list="brandList"
              :collaped=0
              @onSelectChange="onBrandSelectChange"
              :selected="selectedBrandIdList"
            />
          </div-->
        </div>
        <!-- web filter end -->
        <!-- mobile filter -->
        
        <mobileFilter
          :modalName="filterModal"
          :tagList="tagList"
          :brandList="brandList"
          :selectedTag="selectedTagIdList"
          :inventoryChecked="inventoryChecked"
          :selectedBrand="selectedBrandIdList"
          @onBrandSelectChange="onBrandSelectChange"
          @changeParentSelect="changeParentSelect"
          @changeParentInventoryCheckbox="changeParentInventoryCheckbox"
        />
        <!-- mobile filter end-->
        <div class="result-list">
          <p v-show="props.searchInfo != null" class="search-bread">{{ props.searchInfo }}</p>
          <div class="mobag-search">
            <div class="width-for-search">
              <h2 class="title">{{ $t('searchByBike.title') }}</h2>
              <search-by-bike
                :currentBikeSelection="currentBikeSelection"
              ></search-by-bike>
            </div>
          </div>
          <div class="mobile-filter">
            <buttonOrange
             :click="openFilterModal"
             text="Filter"
             style="width:100%;"
            />
          </div>
          <div class="search-result-list">
            <h2 v-show="isProductListEmpty" style="margin-left:20px; font-size:1.4rem">Sorry, no product found</h2>
            <b-container fluid style="padding:0">
              <b-row style="margin:0">
                <b-col v-for="product in productList" cols="6" sm="6" md="6" lg="4" :key="product.id">
                  <product-item :product="product" :key="product.id"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="overflow-auto web" v-show="pageTotal > 1">
            <b-pagination-nav
              :link-gen="linkGen"
              limit="6"
              :number-of-pages="pageTotal || 1"
              @change="onPageNumberChange"
              align="center"
              size="lg"
              style="margin-top:30px;"
              :value="currentPage"
              />
          </div>
        </div>
      </b-row>
    </b-container>
</template>

<script>

import SearchByBike from '../../components/web/SearchByBike.vue'
import ProductItem from '../../components/web/ProductItem.vue'
import Filter from './Filter.vue'
import MobileFilter from '../../components/mobile/Filter.vue'
import ButtonOrange from '../../components/mobile/ButtonOrange.vue'
import {
    getPageTotalByTotalCount,
} from '../../common/Util';

const pageSize = 48;
export default {
  props: {
    props: Object
  },
  data: function() {
    return {
      productList: [],
      isProductListEmpty: false,
      filterModal: 'filter',
      inventoryChecked: false,
      brandList: [],
      tagList: [],
      isFilterOpen: true,
      selectedTagIdList:[],
      selectedBrandIdList: [],
      pageTotal: 1,
      initLoad: true,
      searchUrl: this.props.productUrl,
      currentPage: 1,
      currentBikeSelection: this.props.currentBikeSelection
    }
  },
  components: {
    'search-by-bike': SearchByBike,
    'product-item': ProductItem,
    'webFilter': Filter,
    'mobileFilter': MobileFilter,
    'buttonOrange':ButtonOrange,
  },
  created: function() {
    this.getCatalog()
  },
  watch: {
    props: function(newProps, oldProps) {
      // 先执行refresh和在getCatalog中后执行影响？
      // this.refresh(1);
      this.getCatalog()
      this.currentBikeSelection = newProps.currentBikeSelection
      // console.log('333:' + JSON.stringify(newProps))
      // console.log('444:' + this.currentBikeSelection)
    },
  },
  methods: {
    openFilterModal: function() {
      this.$modal.show(this.filterModal,{})
    },
    changeInventoryCheckbox: function(param) {
      this.inventoryChecked = param
      this.onTagSelectChange({value: this.selectedTagIdList, inventory: param})
    },
    changeParentInventoryCheckbox: function(param) {
      this.inventoryChecked = param
    },
    changeParentSelect: function(params) {
      this.selectedTagIdList = params;
    },
    onTagSelectChange: function(params) {
      let value = params.value;
      let inventory = (params.inventory!== undefined?params.inventory:false) || this.inventoryChecked

      if(this.initLoad) return;
      this.currentPage = 1
      this.searchUrl =
        this.props.productUrl
        + '&product_brand_id_list=' + this.selectedBrandIdList
        + '&inventory=' + inventory
        + '&tag_id_list=' + value;
      this.selectedTagIdList = value;
      this.filtering(1)
    },
    onBrandSelectChange : function(params) {
      if(this.initLoad) return;
      this.currentPage = 1
      this.searchUrl =
        this.props.productUrl
        + '&tag_id_list=' + this.selectedTagIdList
        + '&product_brand_id_list=' + params;
      this.selectedBrandIdList = params;
      this.filtering(1)
    },
    getCatalog: function() {
      Promise.all([this.$api.get(this.props.tagUrl),this.$api.get(this.props.brandUrl)]).then(resArr => {
        const tagRes = resArr[0]
        const brandRes = resArr[1]
        if(tagRes.code == 0) {
          this.tagList = tagRes.data.tagList;
          this.selectedTagIdList = this.initSeletion(this.tagList);
        }
        if(brandRes.code == 0) {
          let lists = brandRes.data.productBrandList;
          let root = [{name:'品牌', id:'-1', children:[]}]
          for(let i = 0; i<lists.length; i++) {
            root[0].children[i] = lists[i];
          }
          this.brandList = root;
          this.selectedBrandIdList = this.initSeletion(this.brandList);
        }
        if(this.initLoad) {
          // 首次进入，传入的有链接上拼好的tag和brand
          if(this.props.tags) {
            // 链接的参数转为数组
            const tagTempArr = this.props.tags.split(',')
            this.tagList.forEach(item=>{
              item.children.forEach(child=>{
                // 取交集，两个数组重合的id即为选中的tag，没有直接用链接上直接传入的tagid
                // 因为当tagList中没有某个链接所带的tagid时，查询产品接口若都传入，界面上没有展示此tag，用户无法感知无法手动取消，导致后面查询结果不准确
                if(tagTempArr.includes(`${child.id}`)) {
                  this.selectedTagIdList.push(`${child.id}`)
                }
              })
            })
          }
          if(this.props.brands) {
            // 逻辑同上
            const brandTempArr = this.props.brands.split(',')
            this.brandList.forEach(item=>{
              item.children.forEach(child=>{
                if(brandTempArr.includes(`${child.id}`)){
                  this.selectedBrandIdList.push(`${child.id}`)
                }
              })
            })
          }
        }
        // 原本放在上面this.initLoad判断中执行，注释watch中refresh拿到了外面
        this.refresh(1);
      })
    },
    initSeletion(list) {
      if(!list) {
        return
      }
      let returnSelectionList = [];
      return returnSelectionList;
    },
    filtering: function(pageIndex) {
      if(this.initLoad) return;
      this.$api.get(this.searchUrl, {
        params: {
          page_size: pageSize,
          page_index: pageIndex,
        }
      })
        .then(data => {
          this.pageTotal = getPageTotalByTotalCount(data.data.totalCount, pageSize);
          this.productList = data.data.productList;
          window.scrollTo(0, 0);
      });
    },
    refresh: function(pageIndex) {
      if(this.props.tagType == 0){
        document.title = 'Meta Stone - Product';
      } else if(this.props.tagType == 4){
        document.title = 'Meta Stone';
      }
      let url = this.props.productUrl
      if (url === '' || typeof url === 'undefined'){
        return;
      }
      // 第一次进入并且链接带有默认参数时
      if(this.initLoad && (this.props.tags || this.props.brands)) {
        url = `${url}&tag_id_list=${this.selectedTagIdList}&product_brand_id_list=${this.selectedBrandIdList}`
      }
      this.$api.get(url, {
        params: {
          page_size: pageSize,
          page_index: pageIndex,
        }
      })
        .then(data => {
          console.log(data)
          this.pageTotal = getPageTotalByTotalCount(data.data.totalCount, pageSize);
          this.productList = data.data.productList;
          this.isProductListEmpty = this.productList.length == 0;
          this.initLoad = false;
        });
    },
    onPageNumberChange: function (page) {
      this.filtering(page)
      this.currentPage = page
    },
    linkGen() {
      return '';
    },
  }
}
</script>

<style scoped>
  .mobag-search{
    display:flex;
    justify-content:center;
    align-items:center;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid #ec2f00;
    padding: 10px 0;
    margin: 10px;
    background-color:#fff;
    h2{ font-size:2rem; font-weight:600; line-height:4rem}
    .title{flex:0 0 auto; margin-left:15px;}
  }
  .search-bread {font-size:2.4rem; font-weight:600; margin: 0 10px 24px;}
  .width-for-search {width:84%}
  .col-md-6 {padding:8px;}
  .row-container {margin:10px;}
  .flex {display:flex}
  .filter{flex:1; margin: 0 10px;}
  .result-list{flex:3}
  .cat-container h2 {font-size:1.8rem; font-weight:600; border-bottom:1px solid #ececec;
   padding-bottom:12px; margin-bottom:9px; padding-top:20px;}
  .mobile-filter{display:none}
  @media screen and(max-width:575px) {
    .mobag-search{display: none;}
    .col-sm-6{padding:2px}
    .col {padding:0}
    .container-fluid {padding:0}
    .row{margin:2px;}
    .mobile-filter{margin:80px 12px 12px 12px;}
  }
  @media screen and(max-width:767px) {
    #webFilter{display:none}
    .mobile-filter{display:block;text-align:center;}
    .search-bread {font-size:1.6rem; font-weight:600; margin: 0 10px 16px;}
  }
  @media screen and (max-width:991px) {
    .width-for-search {width:100%;}
  }
</style>
